import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import BlackFoodCaucusBanner from "../components/BlackFoodCaucusBanner";
import Image from "../components/image"

class BlackFoodCaucus extends Component {
    render() {
        return (
            <Layout
                headerElement={<BlackFoodCaucusBanner siteTitle="The Pan-Canadian Black Food Sovereignty Caucus" />}
                scrollStepInPx={50}
                delayInMs={2}
            >
            <SEO title="The Pan-Canadian Black Food Sovereignty Caucus" />
            <section id="section2 p-0" style={{marginBottom: 80, padding: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="pb-4">Our Mission</h2>
                            <div>
                                <p style={{fontSize: 18}}>
                                    The Pan-Canadian Black Food Sovereignty Caucus advocates on behalf of Canada&#39;s over 1.5
                                    million and growing population of people of African descent and empowers our diverse
                                    communities for the pursuit of Black Food Sovereignty. The Caucus works to transform the
                                    face of the Canadian food movement to include the invaluable contributions of people of
                                    African descent. We support discussing issues that affect Black Food Sovereignty nationally
                                    and engage in developing solutions relating to access to food security, especially for black
                                    children. The Caucus ensures those in decision-making positions across the country
                                    represent the voices of black Canadians for a more equitable food system.<br /><br />
                                    PCBFS Caucus membership is open to all Canadians of African descent committed to working
                                    collectively towards Black Food Sovereignty by taking leadership, building community
                                    capacity, standing in solidarity to advance black food justice, and equipping the younger
                                    generation of black Canadians with food growing skills and farm management to safeguard
                                    our future generations&#39; food needs.<br /><br />
                                    We convene annually to strategize on our vision of Black Food Sovereignty in Canada.<br /><br />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <h2 className="pb-4 text-center">Why is Black Food Sovereignty our Priority?</h2>
                            <div className="d-flex justify-content-center">
                                <img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/caucus-image.png" alt="Food Sovereignty Caucus Survey Image " />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5">
                        <div className="col-lg-12">
                            <h2 className="pb-4">Our Members</h2>
                            <div className="row">
                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/le+logo+2_Plan+de+travail+1+-+Bee%CC%8Cnee%CC%8Cdicte+Allam-Ndoul.png" />
                                    </a>
                                    <h3><a href="#">Aliments</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/Logo+Jardins+Lakou+Main+-+Jean-Philippe+Vee%CC%8Czina.png" />
                                    </a>
                                    <h3><a href="#">Jardins Lakou</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/afb-logo-black.png" />
                                    </a>
                                    <h3><a href="#">Afri-Can FoodBasket</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/africandescent-emblem_en.png" />
                                    </a>
                                    <h3><a href="#">International Decade For People of African Descent</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/afrovan+-+Buni+Kor.jpg" />
                                    </a>
                                    <h3><a href="#">Van Connect</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/akoma.png" />
                                    </a>
                                    <h3><a href="#">Akoma</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/Afua+Asantewaa.png" />
                                    </a>
                                    <h3><a href="#">Afua Asantewaa</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/AXL+2+-+Anan+Lololi.jpg" />
                                    </a>
                                    <h3><a href="#">Afri-Can FoodBasket</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/Bee%CC%87nee%CC%87dicte+Allam-Ndoul.jpg" />
                                    </a>
                                    <h3><a href="#">Allam Ndoul</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/BFST+Logo+bigger+-+Anan+Lololi.png" />
                                    </a>
                                    <h3><a href="#">Ana Lololi</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/jambican+logo+-+c.k.+samuels.jpg" />
                                    </a>
                                    <h3><a href="#">Jambican studio garden</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/Edem+Amegbo.jpg" />
                                    </a>
                                    <h3><a href="#">Au Jardin d'Edem</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/edem_bestresolution+-+Edem+Amegbo.jpg" />
                                    </a>
                                    <h3><a href="#">Edem Amegbo</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/FFF+White+Banner+Transparent+Background+-+Ferron+Family+Farms.png" />
                                    </a>
                                    <h3><a href="#">Ferron</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/Buni+Kor.png" />
                                    </a>
                                    <h3><a href="#">Buni Kor</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/Jean-Philippe+Vee%CC%8Czina.png" />
                                    </a>
                                    <h3><a href="#">Jean Philippe</a>
                                    </h3>
                                </div>

                                <div class="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/Zakiiya+Tafari.jpeg" />
                                    </a>
                                    <h3><a href="#">Zakiiya Tafari</a>
                                    </h3>
                                </div> 

                                <div className="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/+Cheyenne+Jones.jpeg" />
                                    </a>
                                    <h3><a href="#">Cheyenne Jones</a>
                                    </h3>
                                </div>

                                <div className="col-md-4 portfolio-item">
                                    <a href="#">
                                        <img className="img-responsive" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/black-caucus-photos/20210909-16---c.k.-samuels2.png" />
                                    </a>
                                    <h3><a href="#">C.K. Samuels</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </Layout>
        );
    }
}

export default BlackFoodCaucus;